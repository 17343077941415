import React, { useEffect } from 'react'
import type { NextPage } from 'next'
import { Auth } from 'src/features/auth'
import { useRouter } from 'next/dist/client/router'
import { useDispatch } from 'react-redux'

const Home: NextPage = (props) => {
  const router = useRouter()
  const dispatch = useDispatch()

  useEffect(() => {
    if (router.isReady) {
      router.replace('/applications')
    }
  }, [dispatch, props, router])

  return <Auth />
}

export default Home
